<template>
	<div>
		<img :src="proxy.$axios.imgUrl + topImg" class="topImg-m" />
		<div class="Company-box">
			<div class="Company-title">
				Company
				<div class="Company-title-border alcenter">
					<div class="red"></div>
					<div class="blue"></div>
				</div>
			</div>
			<div class="Company-box1">
				<div class="Company-box1-item alcenter" style="margin-top: 0.22rem;">
					<img src="../../assets/image/ic_clinian.png" class="box1-item-left" />
					<div class="box1-item-right ">
						<div>{{info.information?.established}}</div>
						<div>Established in 2016</div>
					</div>
				</div>
				<div class="Company-box1-item alcenter">
					<img src="../../assets/image/ic_yuangong.png" class="box1-item-left" />
					<div class="box1-item-right">
						<div>{{info.information?.employees}}<sup>+</sup></div>
						<div>Employees</div>
					</div>
				</div>
				<div class="Company-box1-item alcenter">
					<img src="../../assets/image/ic_renzheng.png" class="box1-item-left" />
					<div class="box1-item-right">
						<div>{{info.information?.accredited}}</div>
						<div>Accredited</div>
					</div>
				</div>
				<div class="Company-box1-item alcenter">
					<img src="../../assets/image/ic_hegui.png" class="box1-item-left" />
					<div class="box1-item-right">
						<div>{{info.information?.compliant}}</div>
						<div>GLP-compliant</div>
					</div>
				</div>
				<img :src="proxy.$axios.imgUrl + info.information?.about_show_img_1" class="Company-box1-right" />
			</div>
		</div>

		<div class="about-Company-box">
			<div class="Company-title">
				SAIFU Lab’s Key Differentiators
				<div class="Company-title-border alcenter">
					<div class="red"></div>
					<div class="blue"></div>
				</div>
			</div>
			<div class="about-Company-list">
				<img :src="proxy.$axios.imgUrl + info.information?.about_show_img_2" />
				<div class="box-lists main" v-if="info.difference">
					<div class="lists-item" v-for="(item,index) in info.difference.slice(0,3)" :key="index">
						<div class="lists-item-first alcenter">
							<div class="index">0{{index+1}}</div>
							<div class="title">{{item.title}}
								<div class="lists-item-border alcenter">
									<div></div>
									<div></div>
								</div>
							</div>
						</div>
						<div class="lists-item-second">
							<ul>
								<li>{{item.synopsis}}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="about-Company-list">
				<img :src="proxy.$axios.imgUrl + info.information?.about_show_img_3" />
				<div class="box-lists main" v-if="info.difference">
					<div class="lists-item" v-for="(item,index) in info.difference.slice(3,6)" :key="index">
						<div class="lists-item-first alcenter">
							<div class="index">0{{index+4}}</div>
							<div class="title">{{item.title}}
								<div class="lists-item-border alcenter">
									<div></div>
									<div></div>
								</div>
							</div>
						</div>
						<div class="lists-item-second">
							<ul>
								<li>{{item.synopsis}}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="contactUs main">
			<div class="Company-title jucenter">
				SAIFU Lab’s Facility
				<div class="Company-title-border flex-center">
					<div class="red"></div>
					<div class="blue"></div>
				</div>
			</div>
			<div class="about-Company-box2 flex-center">
				<div class="box2-item" v-for="(item,index) in info.lab" :key="index">
					<img :src="proxy.$axios.imgUrl + item.images" />
					<div class="title">{{item.title}}</div>
					<div class="border flex-center"></div>

				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		getCurrentInstance,
	} from 'vue';
	import {
		useRouter
	} from 'vue-router'
	import EventBus from "../../assets/common/event-bus.js"
	const {
		proxy
	} = getCurrentInstance()



	onMounted(() => {
		EventBus.emit("mgheadr", 4)
		getTopimg()
		getInfo()
		getbottomInfo()
	})

	const contactUsList = ref([{
			title: 'Address',
			icon: require('../../assets/image/ic_dizlxwm.png'),
			content: 'address'
		},
		{
			title: 'Website',
			icon: require('../../assets/image/ic_webwz.png'),
			content: 'website'
		},
		{
			title: 'Telephone',
			icon: require('../../assets/image/ic_telphone.png'),
			content: 'telephone'
		},
		{
			title: 'E-mail',
			icon: require('../../assets/image/ic_emaillx.png'),
			content: 'e_mail'
		}
	])

	const info = ref({})

	async function getInfo() {
		let res = await proxy.$axios.get('/api/index/getAboutData')
		info.value = res.data
	}

	const bottomInfo = ref({})

	async function getbottomInfo() {
		let res = await proxy.$axios.get('api/index/getTopBottom')
		bottomInfo.value = res.data.bottom
	}


	const topImg = ref('')

	async function getTopimg() {
		let res = await proxy.$axios.get('/api/index/getTopImg', {
			type: 'about'
		})
		topImg.value = res.data.top_img
	}
</script>

<style scoped>
	.topImg-m {
		width: 100%;
		height: auto;
		display: block;
	}

	.Company-title {
		font-weight: 500;
		font-size: 0.32rem;
		color: #000000;
		padding-bottom: 0.18rem;
		position: relative;
	}

	.Company-title-border {
		height: 0.04rem;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0.64rem;
	}

	.Company-title-border .red {
		height: 100%;
		width: 0.12rem;
		background-color: #E83328;
	}

	.Company-title-border .blue {
		height: 100%;
		width: 0.48rem;
		background-color: #008AD2;
		margin-left: 0.04rem;
	}

	.Company-box {
		padding: 0.42rem 0.3rem 0.38rem;
		background: #F9F9F9;
		box-sizing: border-box;
	}

	.Company-box1-item {
		padding: 0.32rem 0;
		border-bottom: 1px solid #E6E6E6;
	}

	.Company-box1-item .box1-item-left {
		width: 0.64rem;
		height: 0.64rem;
	}

	.Company-box1-item .box1-item-right {
		flex: 1;
		margin-left: 0.26rem;
	}

	.Company-box1-item .box1-item-right>div:first-child {
		font-weight: 500;
		font-size: 0.3rem;
		color: #000000;
		line-height: 0.3rem;
	}

	.Company-box1-item .box1-item-right>div:last-child {
		font-weight: 400;
		font-size: 0.2rem;
		color: #999999;
		line-height: 0.2rem;
		margin-top: 0.16rem;
	}

	.Company-box1-right {
		display: block;
		width: 100%;
		height: auto;
	}

	.about-Company-box {
		margin-top: 0.47rem;
	}

	.about-Company-box .Company-title {
		text-align: center;
	}

	.about-Company-box .Company-title .Company-title-border {
		left: 50%;
		transform: translateX(-50%);

	}

	.about-Company-list {
		margin-top: 0.44rem;
	}

	.about-Company-list>img {
		width: 100%;
	}

	.about-Company-list .box-lists {}

	.about-Company-list .box-lists .lists-item {
		margin-top: 0.5rem;
	}

	.about-Company-list .lists-item-first {
		color: #008AD2;
	}

	.about-Company-list .lists-item-first .index {
		font-weight: 500;
		font-size: 0.48rem;
		line-height: 0.26rem;
		font-style: italic;
	}

	.about-Company-list .lists-item-first .title {
		font-weight: 500;
		font-size: 0.28rem;
		line-height: 0.28rem;
		margin-left: 0.16rem;
		padding-left: 0.09rem;
		padding-bottom: 0.16rem;
		position: relative;
	}

	.about-Company-list .lists-item-first .lists-item-border {
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.about-Company-list .lists-item-first .lists-item-border>div:first-child {
		width: 0.06rem;
		height: 0.06rem;
		background: #008AD2;
		border-radius: 50%;
	}

	.about-Company-list .lists-item-first .lists-item-border>div:last-child {
		width: 0.68rem;
		height: 0.02rem;
		background: #008AD2;
		margin-left: 0.08rem;
	}

	.about-Company-list .lists-item-second {
		font-weight: 400;
		font-size: 0.22rem;
		color: #444444;
		line-height: 0.32rem;
		margin-top: 0.3rem;
	}

	.contactUs {
		margin-top: 0.44rem;
	}

	.contactUs .Company-title-border {
		left: 50%;
		transform: translateX(-50%);
	}

	.about-Company-box2 {
		margin-top: 0.36rem;
		display: grid;
		grid-template-columns: repeat(2, 49%);
		grid-template-rows: auto;
		grid-gap: 0.26rem;
	}

	.about-Company-box2 .box2-item {
		border: 1px solid #EEEEEE;
		font-weight: 500;
		font-size: 0.22rem;
		color: #000000;
		line-height: 0.24rem;
		height: 3.64rem;
	}

	.about-Company-box2 .box2-item>img {
		width: 100%;
		height: 2.5rem;
	}

	.about-Company-box2 .box2-item .title {
		text-align: center;
		padding: 0.28rem 0 0.24rem;
		position: relative;
	}

	.about-Company-box2 .box2-item .border {
		width: 0.48rem;
		height: 0.04rem;
		background: #008AD2;
		margin-top: 0.18rem;
		display: block;
		margin: 0 auto;
	}
</style>